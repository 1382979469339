import ChangeDescription from '@/layouts/Stack/components/ChangeDescription';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';
import translit from '@/mixins/translit';

export default {
  mixins: [showErrorModal, storeMixin, modals, translit],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      description: '',
      isProcessing: false,
      key: '',
    };
  },
  methods: {
    changeS3KeyDescription: function (instance, id) {
      this.key = Object.assign({}, instance);
      const that = this;
      return new Promise(() => {
        const selfName = 'changeS3KeyDescription';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ChangeDescription,
          closeOnBackdrop: false,
          props: {
            description: instance?.description,
            hint: 'Описание ключа необходимо вводить латиницей',
            text: this.$t('description.name'),
          },
          on: {
            change: data => {
              this.description = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('description.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });

                  const preparedText = this.translit(this.description).trim();
                  const params = {
                    description: preparedText,
                    // description: this.description.trim().replaceAll('\n', ' '),
                    key: this.key.access,
                    // type: 'description',
                    id: id,
                  };
                  this.updateDescription(params)
                    .then(async data => {
                      if (data) {
                        that.$modals.close();
                        await this.$store.dispatch('moduleStack/getS3StoragesKeys', id);
                        this.showResModal('Описание S3 ключа изменено.');
                      }
                    })
                    .catch(e => {
                      this.description = '';
                      that.$modals.close();
                      // console.log(e.response.data.badRequest.message);
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  this.description = '';
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updateDescription(params) {
      return this.$store.dispatch('moduleStack/setS3StoragesKeyDescription', params);
    },
  },
};
